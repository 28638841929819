
.SignupForm {
  margin: auto;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  backdrop-filter: blur(40px);
  color: azure;
  padding-bottom: 30px;
}

.SignupForm button {
  margin: auto;
  width: 40%;
}

.SignupForm h1 {
  margin: 0px;
}

.SignupForm input, textarea {
  color: inherit;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 5px;
}

.SignupForm textarea {
  box-sizing: border-box;
  width: 90%;
  height: 4rem;
  resize: none;
  font-family: inherit;
  padding: 5px;
}

.SignupForm input {
  width: 60%;
  height: 1.5rem;
  text-align: center;

}

.SignupForm .form-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}