.RatingForm {
  margin: 5px auto;
}

.RatingForm > button {
  margin: 0 10px;
  font-family: inherit;
  color: white;
  border: 1px solid black;
  border-radius: 5px;
  width: 75px;
  height: 35px;
  font-size: 16px;
  transition-duration: .2s;
}

.like {
  background-color: green;
}

.dislike {
  background-color: maroon;
}

button:hover {
  opacity: 85%;
  cursor: pointer;
}