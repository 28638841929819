.Navbar a {
  text-decoration: none;
  color: inherit;
}

.Navbar a.active {
  font-weight: 700;
  color: royalblue;
}

.Navbar {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

.Navbar-links {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  padding-right: 40px;
}
