.MatchCard-button {
  margin: 10px 10px;
  font-family: inherit;
  color: white;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 16px;
  transition-duration: .2s;
  padding: 8px 12px;
  background-color: royalblue;
}
