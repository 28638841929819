.MessageLog {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MessageLog-field {
  background-color: bisque;
  opacity: 95%;
  padding: 5px 10px;
  margin-bottom: 10px;
  height: 300px;
  border-radius: 5px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.MessageLog-form {
  display: inherit;
  flex-direction: inherit;
  align-items: inherit;
}

.MessageLog-form > textarea {
  box-sizing: border-box;
  width: 400px;
  height: 4rem;
  resize: none;
  font-family: inherit;
  padding: 5px;
  backdrop-filter: blur(15px);
  color: white
}

.MessageLog-form > textarea::placeholder {
  color: inherit
}

.MessageLog-message {
  text-align: left;
}
.MessageLog-from {
  font-size: 10px;
  font-style: italic;
  color: royalblue;
}