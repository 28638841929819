.IsLoading {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(7px);
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.IsLoading-spinner {
  width: 125px;
  height: 125px;
  border: 3px solid;
  border-color: royalblue transparent royalblue transparent;
  border-radius: 50%;
  animation: spin 2s linear infinite, grow 10s linear infinite
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes grow {
  to {
    transform: scale(2);
  }
}