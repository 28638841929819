.FriendCard {
  width: 35vw;
  margin: auto;
  background-color: #f1d4ac !important;
  border-radius: 10px;
  padding: 20px 0px;
}

.FriendCard >img {
  width: 50%;
  border-radius: 10px;
}